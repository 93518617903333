<template>
    <div>

        <!-- If Job hasn't started -->
        <job-start
                v-if="job && canViewJob && showJobStartOverlay"
                :job="job"
                :devices="devices"
        ></job-start>

        <!-- Job -->
        <template v-if="job && canViewJob && !showJobStartOverlay">
            <div class="alert alert-info alert-centered rounded-0 mb-0" v-if="job.company_name && job.company_name !== 'Reviveaphone'">
                <animated-info></animated-info>
                <template v-if="job.is_care_plus">Samsung Care+</template>
                <template v-if="!job.is_care_plus">{{ job.company_name }}</template>
                <template v-if="job.company_name !== 'Samsung In-warranty' && job.in_warranty">In-warranty</template>
                <template v-if="job.carrier"> - {{ job.carrier }}</template>
                <template v-if="isGenuineAppleRepair"> - Genuine Apple.</template>

            </div>

            <template v-if="showMap">
                <!-- Map -->
                <gmap-map
                        :center="center"
                        class="map-container"
                        ref="map"
                        v-if="markers.length > 0 && isOnline"
                        :zoom="12"
                >
                    <gmap-marker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            @click="center=m.position"
                    ></gmap-marker>
                </gmap-map>

                <!-- No job map -->
                <div class="container pb-0" v-if="markers.length === 0">
                    <div class="card mb-0">
                        <empty-state
                                class="py-3 icon-all-muted"
                                icon="map"
                                inline
                                small
                                subtitle="There's no map for this job"
                                title=""
                        >
                        </empty-state>
                    </div>
                </div>

                <!-- Offline -->
                <div class="container pb-0" v-if="isOffline">
                    <div class="card mb-0">
                        <empty-state
                                class="py-3 icon-all-muted"
                                icon="signal-none"
                                inline
                                small
                                subtitle="A connection is required for the map"
                                title="You're offline"
                        >
                        </empty-state>
                    </div>
                </div>
            </template>

            <div class="container">

                <!-- Standard job details -->
                <standard-job-details
                        v-if="standardDetails"
                        :jobId="jobId"
                        :markers="markers"
                        :showJobCompleteButton="shouldShowJobCompleteButton">
                </standard-job-details>

                <!-- Refurbishment job details -->
                <refurbishment-job-details
                        v-if="job.job_type === 'refurbishment'"
                        :jobId="jobId"
                        :markers="markers"
                        :showJobCompleteButton="shouldShowJobCompleteButton">
                </refurbishment-job-details>

                <!-- Mail-in/re-repair job details -->
                <mail-in-job-details
                        v-if="job.job_type === 'mail-in' || job.job_type === 'customer-mail-in' || job.job_type === 're-repair'"
                        :jobId="jobId"
                        :showJobCompleteButton="shouldShowJobCompleteButton">
                </mail-in-job-details>

                <!-- Loading -->
                <loading-state
                        v-if="loading"
                        title="Loading job details">
                </loading-state>

                <!-- Tabs -->
                <b-tabs v-if="!loading">
                    <!-- Devices -->
                    <b-tab
                        active
                        :title="`Devices (${devices.length})`"
                        v-if="devices.length > 0">
                        <div class="title">
                            <h1>Devices</h1>
                            <h5 class="text-subtitle">{{ devices.length }} devices</h5><!-- TODO: Pluralise this -->
                        </div>
                        <div class="card-grid grid-auto-rows-auto vertical">
                            <div class="card-link-container" v-for="device in devices" :key="device.id">
                                <device-job-details-card
                                        :device="device"
                                        :job="job"
                                        :assetsAwaitingParts="assetsAwaitingParts"
                                        @openNoteModal="openDeviceNoteModal(device.id)">
                                </device-job-details-card>
                            </div>
                        </div>
                    </b-tab>
                    <!-- Notes -->
                    <b-tab
                        :title="`Notes (${noteTotalCount})`">
                        <div class="title">
<!--                            <h1>Notes <button class="btn btn-success" @click="openJobNoteModal()">Add job note</button></h1>-->
                            <h1>Notes</h1>
                            <h5 class="text-subtitle">{{noteTotalCount}} notes</h5><!-- TODO: Pluralise this -->
                        </div>

                        <!-- Job Notes -->
                        <template v-if="jobNotes.length > 0">
                            <label>Job Notes</label>
                            <div class="card-grid vertical mb-0">
                                <div class="card-grid vertical mb-0">
                                    <div class="card-link-container">
                                        <note-item :jobNotes="jobNotes"></note-item>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <!-- Device Notes -->
                        <template v-if="deviceNotes.length > 0">
                            <label>Device Notes</label>
                            <div class="card-grid vertical">
                                <div class="card-grid vertical">
                                    <div class="card-link-container">
                                        <note-item :jobNotes="deviceNotes"></note-item>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="alert alert-info alert-sm text-sm">
                            <animated-info></animated-info>
                            <div class="alert-content">Add a note to a device by clicking on the progress bar at the top of the repair process pages</div>
                        </div>

                        <div class="card card-body card-body-sm" v-if="jobNotes.length < 1 && deviceNotes.length < 1">
                            <empty-state
                                    faIcon="sticky-note"
                                    inline
                                    small
                                    title="No notes have been added">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- Signature tab -->
                    <b-tab
                        title="Signature"
                        v-if="signatureImageUrl">
                        <img :src="signatureImageUrl" class="signature-preview"/>
                    </b-tab>

                    <!-- Book Revisit -->
    <!--                    <b-tab-->
    <!--                        title="Book Revisit"-->
    <!--                        v-if="job.job_type !== 'general'">-->
    <!--                        <h1>Book Revisit</h1>-->
    <!--                        <div class="card card-body">-->
    <!--                            <p>-->
    <!--                                If you need to return to this repair at a later date, click the button below to book a revisit. <strong>Please note, this will have an affect on your KPIs</strong>, so only book a revisit if it is absolutely necessary.-->
    <!--                            </p>-->
    <!--                            <a href="#" class="btn btn-gradient-success btn-lg m-auto mb-2 px-5 icon-right">Book Revisit <i class="fa fa-external-link"></i></a>&lt;!&ndash; TODO: Link to the book revisit process (:href="`/support/booking/order?email=${email}&order_id=${orderId}`") &ndash;&gt;-->
    <!--                        </div>-->
    <!--                    </b-tab>-->
                </b-tabs>

                <!-- Mark as complete modal -->
                <job-complete-modal :jobId="jobId"></job-complete-modal>

                <!-- Job Status Modal -->
                <update-status-modal></update-status-modal>

                <!-- Note Modal -->
                <note-modal v-if="job" :jobId="jobId" :noteableId="noteableId" :noteableType="noteableType" ></note-modal>
            </div>

            <!-- Waiver signature pad -->
            <b-modal
                    body-class="px-0 pb-0"
                    hide-footer
                    id="waiver-signature-modal"
                    size="fs"
                    title="Sign Waiver">
                <signature-pad
                        completeJob
                        :jobId="jobId"
                        model-id="waiver-signature-modal"
                        @signaturePadSigned="waiverHasBeenSigned($event)">
                </signature-pad>
            </b-modal>

        </template>

        <!-- Job does not exist -->
        <div class="container" v-if="canViewJob && !job">
            <empty-state
                    emptyIcon
                    icon="tools"
                    title="There's no job here"
                    subtitle="Try another job or contact your SSM if you think this is an error">
                <slot>
                    <job-search-card v-if="isJobManager" class="mt-5"></job-search-card>
                </slot>
            </empty-state>
        </div>

        <!-- Job does not exist -->
        <div class="container" v-if="!canViewJob">
            <empty-state
                    emptyIcon
                    :jobId="jobId"
                    icon="tools"
                    title="This job doesn't belong to you..."
                    subtitle="Try another job or contact your SSM if you think this is an error">
                <slot>
                    <job-search-card v-if="isJobManager" class="mt-5"></job-search-card>
                </slot>
            </empty-state>
        </div>

        <!-- Can't start job instructions modal -->
        <job-cant-start-modal :jobId="jobId"></job-cant-start-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        /* global google */

        props:['jobId', 'revisitBooked'],

        data() {
            return {
                assetsAwaitingParts: false,
                canViewJob: true,
                center: null,
                completedDevices: [],
                loading: true,
                markers: [],
                showJobStartOverlay: false,
                showMap: true,
                standardDetails: true,
                showJobCompleteButton: false
            };
        },

        async mounted() {
            this.fetchJobData();
            if (this.revisitBooked) {
                this.displayToast({text: 'Revisit has been booked', type: 'success'});
            }
        },

        watch: {
            jobId(jobId) {
                if (jobId) {
                    this.fetchJobData();
                }
            },
        },

        computed: {
            ...mapGetters([
                "deviceAssets",
                "devices",
                "deviceNotes",
                "deviceRepairs",
                "isJobManager",
                "isTechnician",
                "isInHouseTechnician",
                "isRefurbOrMailIn",
                "job",
                "jobNotes",
                "noteableId",
                "noteableType",
                "signatureImage",
                "user",
                'waiverSigned'
            ]),

            /** Total count of notes */
            noteTotalCount() {
                return this.jobNotes.length + this.deviceNotes.length;
            },

            /** URL for signature image */
            signatureImageUrl() {
                if (this.signatureImage) {
                    return `https://firebasestorage.googleapis.com/v0/b/sinuous-vortex-201512.appspot.com/o/signatures%2F${this.jobId}%2F${this.signatureImage.filename}?alt=media`;
                }

                return false;
            },

            /** Determine if the complete job button should be shown */
            shouldShowJobCompleteButton() {
                if (!this.job.complete) {
                    let completedDevices = [];

                    if (this.devices.length) {
                        this.devices.forEach(device => {
                            if (device.status_id !== 10) {
                                completedDevices.push(device);
                            }
                        });

                        if (completedDevices.length === this.devices.length) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }

                return false;
            },

          isGenuineAppleRepair() {
            let genuine = false;

            if (this.devices.length) {
              this.devices.forEach(device => {
                if(device.genuine && device.make_id == 1) {
                  genuine = true;
                }
              });
            }

            return genuine;
          }
        },

        methods: {
            ...mapActions([
                "addSignature",
                "loadDeviceAssets",
                "displayToast",
                "loadJob",
                "loadDevicesForJob",
                "loadDeviceNotes",
                "loadDeviceRepairs",
                "loadJob",
                "loadJobNotes",
                "loadSignatureImage",
                "setAdditionalData",
                "setDeviceStatus",
                "setNoteableId",
                "setNoteableType",
                "setWaiverName",
                "setWaiverSigned"
            ]),

            /** Check if any assets are awaiting parts */
            checkAssetsAwaitingParts() {
                this.deviceAssets.forEach(asset => {
                    if (asset.status_code === 'awaiting-stock') {
                        this.assetsAwaitingParts = true;
                    }
                });
            },

            /** Fetch the order id and devices */
            fetchJobData() {
                this.loadJob({
                    jobId: this.jobId
                }).then(() => {
                    switch (this.job.job_type) {
                        case 'customer-mail-in':
                        case 'mail-in':
                        case 're-repair':
                            this.showJobStartOverlay = false;
                            this.showMap = false;
                            this.standardDetails = false;
                            break;
                        case 'refurbishment':
                            this.showJobStartOverlay = false;
                            this.showMap = true;
                            this.standardDetails = false;
                            break;
                        default:
                            this.showJobStartOverlay = true;
                            this.showMap = true;
                            this.standardDetails = true;
                    }
                    if (this.job.complete ||
                        this.job.started_journey ||
                        this.job.cant_start ||
                        this.isJobManager) {
                        this.showJobStartOverlay = false;
                    }
                    this.loadJobNotes({
                        jobId: this.jobId
                    });
                    this.loadSignatureImage({
                        jobId: this.jobId,
                        imageableId: this.jobId,
                    });
                });

                this.loadDevicesForJob({
                    jobId: this.jobId
                }).then(() => {
                    this.loading = false;
                    this.userCanViewJob();
                    if (this.showMap) {
                        this.initMap();
                    }
                    if (this.devices.length) {
                        this.devices.forEach(device => {
                            this.loadDeviceNotes({
                                jobId: this.jobId,
                                deviceId: device.id
                            });
                            if (this.job.job_type === 'customer-mail-in' || this.job.job_type === 'mail-in' || this.job.job_type === 're-repair') {
                                this.loadDeviceAssets({
                                    jobId: this.jobId,
                                    deviceId: device.id
                                }).then(() => {
                                    this.checkAssetsAwaitingParts();
                                });
                            }
                        });
                    }
                });
            },

            /** Initialise the map */
            initMap() {
                let marker = {
                    lat: parseFloat(this.job.latitude),
                    lng: parseFloat(this.job.longitude),
                };

                this.markers.push({position: marker});
                this.center = marker;

                this.$nextTick(() => {
                    this.$refs.map.$mapPromise.then((map) => {
                        let trafficLayer = new google.maps.TrafficLayer;
                        trafficLayer.setMap(map);
                    });
                });
            },

            /** Open the job note modal */
            openJobNoteModal() {
                this.setNoteableId(this.jobId);
                this.setNoteableType("job");
                this.$bvModal.show('note-modal');
            },

            /** Check if the user can view the job */
            userCanViewJob() {
                if (this.isJobManager) {
                    this.canViewJob = true;

                } else if (this.isInHouseTechnician && this.isRefurbOrMailIn) {
                    this.canViewJob = true;

                } else if (this.user.technician && this.user.technician.id === this.job.technician_id) {
                    this.canViewJob = true;

                } else {
                    this.canViewJob = false;
                }
            },

            /** Open the note modal */
            openDeviceNoteModal(device) {
                this.setNoteableId(device);
                this.setNoteableType("device");
                this.$bvModal.show('note-modal');
            },

            /** The waiver has been signed */
            waiverHasBeenSigned(signaturePadData) {
                this.showJobCompleteButton = false;

                this.$bvModal.hide('waiver-signature-modal');

                this.addSignature({
                    jobId: this.jobId,
                    imageableId: this.jobId,
                    imageableType: "App\\Models\\Waiver",
                    imageData: signaturePadData,
                    signatureType: "job-complete"
                });
            }
        }
    }
</script>